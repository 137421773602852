<template>
  <div class="welcome" v-if="isVisible">
    <div class="welcome-content">      
      <h2 class="welcome__title">{{ message.title }}</h2>
      <p class="welcome__text" v-html="formattedDescription"></p>
      <p class="welcome__progress-text">{{ message.progress_label }}</p>
      <div class="welcome__progress">
        <div class="welcome__progress-bar" :style="{ width: message.percent * 100 + '%' }"></div>
      </div>
      <p class="welcome__end">{{ message.finish_assessment_label }}</p>
      <button class="welcome__close-btn" @click="closePopup">
        Ок
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      message: {},
      isVisible: false
    };
  },
  computed: {
    formattedDescription() {
      return this.message.description.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      );
    }
  },
  methods: {
    fetchMessage() {
      const apiUrl = `/api/pis/welcome`;
      axios.get(apiUrl)
        .then(response => {
          this.message = response.data;
          this.showPopupIfNeeded();
        })
        .catch(error => {
          console.error(error);
        });
    },
    showPopupIfNeeded() {
      const lastShown = localStorage.getItem('lastPopupShown');
      const now = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000; // Миллисекунд в одном дне
      const showPopupParam = this.$route.query.popup;

      if (!lastShown || now - lastShown > oneDay || showPopupParam === 'true') {
        this.isVisible = true;
        localStorage.setItem('lastPopupShown', now);
      }
    },
    closePopup() {
      this.isVisible = false;
    }
  },
  mounted() {
    this.fetchMessage();
  }
};
</script>