<template>
  <footer class="footer">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="footer__inner">
          <div class="footer__content">
            <div class="footer__logo">
              <a href="/">
                <img src="../assets/img/logo.png" alt="logo">
              </a>
            </div>
            <div class="footer__menu">
              <a href="/" class="footer__menu-item">О проекте</a>
              <a href="/" class="footer__menu-item">Контакты</a>
              <a href="/" class="footer__menu-item">Организаторам мероприятий</a>
            </div>
          </div>
          <div class="footer__copy">
            <p class="footer__copy-text">Pis Activities © 2024 Все права защищены</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template> 

<script>
export default {
  name: 'Footers'
}
</script>