<template>
  <Headers @filtered-events="handleFilteredEvents" @loading-state="handleLoadingState"
    @categories-filter="handleCategoriesFilter" />
  <div :class="{'mainPage': true, 'load': loading}">
    <div v-if="page === ''">
      <section v-if="events && events.length > 0 && flagNewEvents" class="events">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="events__inner" ref="eventsInner">
              <div class="events__item" v-for="(event, index) in events" :key="index" @click="fetchEvents(event.id)"
                :style="{ height: eventHeight }">
                <div class="events__item-top">
                  <div class="events__item-info">
                    <p class="events__item-date mob-rem">{{ formatDate(event.startTime, event.finishTime) }}<sup
                        v-if="getDayDifference(event.startTime, event.finishTime)">{{
                        getDayDifference(event.startTime,
                        event.finishTime) }}</sup></p>
                    <p class="events__item-place">{{ event.addr }}
                    </p>
                    <p class="events__item-name mob-add">{{ event.shortTitle }}</p>
                  </div>
                  <div class="events__item-subinfo">
                    <p class="events__item-date mob-add">{{ formatDate(event.startTime, event.finishTime) }}<sup
                        v-if="getDayDifference(event.startTime, event.finishTime)">{{
                        getDayDifference(event.startTime,
                        event.finishTime) }}</sup></p>
                    <p class="events__item-name mob-rem">{{ event.shortTitle }}</p>
                    <button class="events__item-fav">
                      <i>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M24 1.86258C20 1.86258 17.9875 3.58758 16 5.86258C14.0125 3.58758 12 1.86258 8 1.86258C4 1.86258 0 4.53133 0 12.5313C0 16.5313 4 24.5313 16 31.2001C28 24.5313 32 16.5313 32 12.5313C32 4.53133 28 1.86258 24 1.86258ZM16 28.1251C5.10625 21.6626 2.66875 14.7313 2.66875 12.5313C2.66875 8.88133 3.59375 4.53133 8 4.53133C10.8 4.53133 12.1625 5.52508 13.9937 7.61883L16 9.86258L18.0063 7.61883C19.8375 5.52508 21.2 4.53133 24 4.53133C28.4062 4.53133 29.3312 8.88133 29.3312 12.5313C29.3312 14.7313 26.8938 21.6626 16 28.1251Z"
                            fill="white" />
                          <path d="M2.5 4.5L10.5 3.5L16 7L21 3.5L28.5 5L30.5 11L27 21L17 29L4 20L1.5 12.5L2.5 4.5Z"
                            fill="none" />
                        </svg>

                      </i>
                    </button>
                  </div>
                </div>
                <div class="events__item-content">
                  <img class="events__item-icon" :src="`/api/img/icons/${event.organizer.logo}`" alt="">
                  <p class="events__item-text">{{ event.shortDescription }}</p>
                </div>
                <div class="events__item-poster" :style="{ backgroundColor: '#' + event.color }" v-if="event.rect">
                  <img class="events__item-poster" :id="`img-${event.id}`" :src="processedImages[event.id]" alt="">
                </div>
                <div class="events__item-poster" :style="{ backgroundColor: '#' + event.color }" v-else>
                  <img class="events__item-poster" :src="getImgUrl(event.posterName)" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div v-else class="events__placeholder">
        <div class="container">
          <div class="container__elem container__elem-2">
            <div class="events__placeholder-inner">
              <p v-if="!loading" class="events__placeholder-text">Нет запланированных мероприятий {{
                this.selectedDateTextPlaceHolder.toLowerCase()
                }}
                с выбранными категориями.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map" v-else-if="page === 'map'">
      <div class="container">
        <div class="container__elem container__elem-12">
          <div class="map__inner">
            <div class="map__info">
              <h2 class="map__info-title">Мероприятие</h2>
              <div class="map__info-item">
                <img src="../assets/img/metka1.png" alt="" class="map__info-img">
                <p class="map__info-text">Запланировано </p>
              </div>
              <div class="map__info-item">
                <img src="../assets/img/metka2.png" alt="" class="map__info-img">
                <p class="map__info-text">Сейчас идет </p>
              </div>
            </div>
            <div v-if="showEventModal" class="map__event">
              <div v-if="selectedEvent.rect" class="map__event-img-wrap"
                :style="{ backgroundColor: '#' + selectedEvent.color }">
                <img class="map__event-img" :id="`img-${selectedEvent.id}`" :src="getImgCropMap(selectedEvent)" alt="">
              </div>
              <div v-else class="map__event-img-wrap" :style="{ backgroundColor: '#' + selectedEvent.color }">
                <img class="map__event-img" :src="getImgUrl(selectedEvent.posterName)" alt="">
              </div>
              <div class="map__event-content">
                <h2 class="map__event-title">{{ selectedEvent.title }}</h2>
                <div class="map__event-info">
                  <div class="map__event-text">
                    <p class="map__event-date">{{ formatDatePopup(selectedEvent.startTime) }}</p>
                    <p class="map__event-time">{{ formatTimePopup(selectedEvent.startTime,
                      selectedEvent.finishTime) }}<sup
                        v-if="getDayDifference(selectedEvent.startTime, selectedEvent.finishTime)">{{
                        getDayDifference(selectedEvent.startTime, selectedEvent.finishTime) }}</sup></p>
                    <p class="map__event-place">{{ selectedEvent.addr}}</p>
                  </div>
                  <div class="map__event-link" @click="fetchEvents(selectedEvent.id)">
                    <img src="../assets/img/link-icon.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="map__content" ref="mapContainerList" style="position: relative;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar" v-else-if="page === 'calendar'">
      <div class="container">
        <div class="container__elem container__elem--12">
          <!-- <div v-if="selectedDateText === 'На неделю'" class="calendar__week">
            <div class="week__controls">
              <button @click="prevWeek" :disabled="isPrevWeekDisabled()">
                <i>
                  <svg width="31" height="44" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.78802 19.6127L25.1832 2.56885C27.1581 1.06579 30 2.47425 30 4.95612V39.0439C30 41.5257 27.1581 42.9342 25.1832 41.4312L2.78803 24.3873C1.21048 23.1867 1.21048 20.8133 2.78802 19.6127Z"
                      stroke="#232323" fill="#000" stroke-width="2" />
                  </svg>

                </i>
              </button>
              <span>{{ weekDateRange }}</span>
              <button @click="nextWeek">
                <i>
                  <svg width="31" height="44" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M28.212 24.3873L5.81685 41.4311C3.84188 42.9342 1 41.5257 1 39.0439L1 4.95612C1 2.47425 3.84187 1.06579 5.81684 2.56885L28.212 19.6127C29.7895 20.8133 29.7895 23.1867 28.212 24.3873Z"
                      stroke="#232323" fill="#000" stroke-width="2" />
                  </svg>

                </i>
              </button>
            </div>
            <div class="week__header">
              <div class="week__cell week__cell--header"></div>
              <div v-for="(day, index) in weekdays" :key="index"
                :class="['week__cell', 'week__cell--header', { 'today': isTodayWeek(getDateInCurrentWeekForToday(index)) }]">
                {{ day.dayOfWeek }} <span>{{ getDateInCurrentWeek(day.date) }}</span>
              </div>
            </div>
            <div class="week__body">
              <div class="week__time-column">
                <div v-for="time in times" :key="time" class="week__time-slot">{{ time }}</div>
              </div>
              <div v-for="(day, index) in weekdays" :key="index" class="week__day-column">
                <div v-for="time in getEventTimeSlots()" :key="time" class="week__event-slot">
                  <div v-for="(event, eventIndex) in allEvents" :key="eventIndex">
                    <div v-if="isEventInDayAndTime(event, day.date, time)" class="week__event">
                      <div class="week__event-inner">
                        <div class="week__event-title">{{ event.title }}</div>
                        <div class="week__event-time">{{ formatDateTime(event.startTime, event.finishTime) }}<sup
                            v-if="getDayDifference(event.startTime, event.finishTime)">{{
                              getDayDifference(event.startTime, event.finishTime) }}</sup></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="calendar__month">
            <div class="month">
              <div class="month__header">
                <button @click="prevMonth" :disabled="isPrevMonthDisabled">
                  <i>
                    <svg width="31" height="44" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.78802 19.6127L25.1832 2.56885C27.1581 1.06579 30 2.47425 30 4.95612V39.0439C30 41.5257 27.1581 42.9342 25.1832 41.4312L2.78803 24.3873C1.21048 23.1867 1.21048 20.8133 2.78802 19.6127Z"
                        stroke="#232323" fill="#000" stroke-width="2" />
                    </svg>

                  </i>
                </button>
                <span>{{ currentMonth }}</span>
                <button @click="nextMonth" :disabled="isNextMonthDisabled">
                  <i>
                    <svg width="31" height="44" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M28.212 24.3873L5.81685 41.4311C3.84188 42.9342 1 41.5257 1 39.0439L1 4.95612C1 2.47425 3.84187 1.06579 5.81684 2.56885L28.212 19.6127C29.7895 20.8133 29.7895 23.1867 28.212 24.3873Z"
                        stroke="#232323" fill="#000" stroke-width="2" />
                    </svg>

                  </i>
                </button>
              </div>
              <div class="month__weekdays">
                <div v-for="day in weekdays" :key="day" class="month__weekday">{{ day.dayOfWeek }}</div>
              </div>
              <div class="month__days">
                <div v-for="(day, index) in daysInMonth" :key="index"
                  v-bind:class="['month__day', isToday(new Date(day.date)) ? 'today' : '']">
                  <div class="month__date">{{ new Date(day.date).getDate() }}</div>
                  <div class="month__events" v-if="!loading">
                    <template v-if="day.events.length > 2">
                      <div class="month__events-count-container"
                        @click="selectedDay = new Date(day.date); showPopupEvents(day.events)">
                        <img src="../assets/img/popupIcon.png" class="month__events-img">
                        <span class="month__events-count">{{ day.events.length }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div v-for="event in day.events" :key="event.id" @click="fetchEvents(event.id)" class="month__event">
                        <p class="month__event-title">{{ event.shortTitle }}</p>
                        <p class="month__event-time">{{ formatDateTime(event.startTime, event.finishTime) }}<sup
                            v-if="getDayDifference(event.startTime, event.finishTime)">{{
                              getDayDifference(event.startTime, event.finishTime) }}</sup></p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
    <transition name="fade">
      <div class="popupEvents" v-if="showEventsPopup" @click.self="hidePopup">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="popupEvents__inner">
              <div class="popupEvents__content">
                <div class="popupEvents__head">
                  <h2 class="popupEvents__title">{{ currentDateText }}</h2>
                  <button class="popupEvents__close" @click="hidePopupEvents">
                    <i>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5455 1.45508L1.45459 14.546M1.45459 1.45508L14.5455 14.546" stroke="#232323"
                          stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </i>
                  </button>
                </div>
                <ul class="popupEvents__list">
                  <li class="popupEvents__list-item" v-for="event in currentDayEvents" :key="event.id">
                    <div class="popupEvents__list-link" @click="hideEventsPopupAndOpenPopup(event)">
                      <p class="popupEvents__list-text">{{ event.title }}</p>
                      <p class="popupEvents__list-time">{{ formatDateTime(event.startTime, event.finishTime) }}<sup
                          v-if="getDayDifference(event.startTime, event.finishTime)">{{
                          getDayDifference(event.startTime,
                          event.finishTime) }}</sup></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="isPopupVisible" class="popup" ref="popup">
      <div class="event__close" @click="closePopup">
        <i>
          <!-- Иконка закрытия -->
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#232323" fill-opacity="0.5" />
            <path
              d="M23.7071 29.427L22.9997 30.1342L23.7071 30.8414L35.4284 42.5599C35.5062 42.6377 35.5499 42.7432 35.5499 42.8533C35.5499 42.9634 35.5062 43.069 35.4284 43.1468C35.3505 43.2246 35.245 43.2683 35.1349 43.2683C35.0248 43.2683 34.9193 43.2246 34.8414 43.1468L22.1223 30.4276L22.1214 30.4267C22.0829 30.3884 22.0524 30.3428 22.0316 30.2926C22.0107 30.2423 22 30.1885 22 30.1342C22 30.0798 22.0107 30.026 22.0316 29.9758C22.0524 29.9256 22.0829 29.88 22.1214 29.8416L22.1223 29.8407L34.8414 17.1216C34.9193 17.0437 35.0248 17 35.1349 17C35.245 17 35.3505 17.0437 35.4284 17.1216C35.5062 17.1994 35.5499 17.305 35.5499 17.415C35.5499 17.5251 35.5062 17.6306 35.4285 17.7084C35.4284 17.7084 35.4284 17.7085 35.4284 17.7085L23.7071 29.427Z"
              stroke="white" stroke-width="2" />
          </svg>

        </i>
      </div>
      <div class="event__link" @click="shareEvent">
        <i>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#232323" fill-opacity="0.5" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M31.9442 32.231V36.7577C31.9444 36.9868 32.0106 37.2109 32.1348 37.4033C32.259 37.5957 32.436 37.7483 32.6446 37.8429C32.8532 37.9375 33.0846 37.97 33.3111 37.9365C33.5377 37.9031 33.7499 37.8051 33.9223 37.6544L44.5924 28.427C45.1359 27.9515 45.1359 27.1078 44.5924 26.6323L33.9236 17.2949C33.7512 17.1442 33.5391 17.0462 33.3125 17.0128C33.0859 16.9793 32.8545 17.0119 32.6459 17.1064C32.4373 17.201 32.2603 17.3536 32.1362 17.546C32.012 17.7384 31.9458 17.9626 31.9456 18.1916V22.721C23.0592 23.278 18.7131 28.6444 17.4225 35.4766C17.2841 36.2158 17.1807 36.9611 17.1128 37.7101C17.0408 38.4994 17.0041 39.3037 17 40.1147V40.2302C17 40.4367 17.2785 40.4965 17.375 40.3144L17.4198 40.2302C18.9082 37.4796 21.1395 35.2026 23.8594 33.6588C25.4082 32.7798 27.2055 32.3573 29.2271 32.2351L29.3004 32.231C30.1427 32.1834 31.0258 32.1862 31.9442 32.231ZM21.361 32.0082C21.7387 31.7582 22.1245 31.5205 22.5198 31.2963C25.3878 29.6687 28.7149 29.3563 32.0746 29.5166L34.6613 29.6416V33.4224L41.484 27.5222L34.6627 21.5527V25.2724L32.1154 25.4314C27.3781 25.7289 24.4164 27.5399 22.5715 30.0016C22.1037 30.6293 21.6974 31.3013 21.361 32.0082Z"
              fill="white" />
          </svg>

        </i>
      </div>
      <div v-if="isLinkCopied" class="event__copy-message">Ссылка скопирована!</div>
      <div class="event">
        <div class="event__inner">
          <div class="event__content">
            <!-- Ваш код для отображения даты, времени, места -->
            <div class="event__top">
              <img class="event__top-icon" :src="`/api/img/icons/${selectedEvent.organizer.logo}`" alt="">
              <div class="event__top-items">
                <div class="event__top-item">
                  <p class="event__top-title">Дата</p>
                  <p class="event__top-text event__top-text--date">{{ formatDatePopup(selectedEvent.startTime) }}</p>
                </div>
                <div class="event__top-item">
                  <p class="event__top-title">Время</p>
                  <p class="event__top-text event__top-text--time">{{ formatTimePopup(selectedEvent.startTime,
                    selectedEvent.finishTime) }}<sup
                      v-if="getDayDifference(selectedEvent.startTime, selectedEvent.finishTime)">{{
                      getDayDifference(selectedEvent.startTime, selectedEvent.finishTime) }}</sup></p>
                </div>
                <div class="event__top-item">
                  <p class="event__top-title">Место</p>
                  <p class="event__top-text event__top-text--place">
                    {{ selectedEvent.addr }}
                  </p>
                </div>
              </div>
            </div>

            <div class="event__info">
              <!-- Данные о мероприятии -->
              <h2 class="event__info-name">{{ selectedEvent.title }}</h2>
              <p class="event__info-text" v-html="formattedDescr(selectedEvent.description)"></p>
              <div v-if="selectedEvent.link" class="event__info-source">
                <p>Источник:</p>
                <a :href="selectedEvent.link">{{ selectedEvent.link }}</a>
              </div>
            </div>
          </div>
          <!-- Постер мероприятия -->
          <div class="event__poster-wrap" v-if="selectedEvent.rect !== null"
            :style="{ backgroundColor: '#' + selectedEvent.color }">
            <img class="event__poster" :id="`imgCor-${selectedEvent.id}`" :src="getImgCropCon(selectedEvent)" alt="">
          </div>
          <div class="event__poster-wrap" v-else :style="{ backgroundColor: '#' + selectedEvent.color }">
            <img class="event__poster" :src="getImgUrl(selectedEvent.posterName)" alt="">
          </div>
        </div>
        <!-- Карта места проведения мероприятия -->
        <div class="event__map" ref="mapContainer" style="position: relative;"></div>
      </div>
      <!-- Затемнение фона -->
    </div>
  </div>
  <Welcome />
</template>

<script>

import axios from 'axios'
import Headers from '@/components/Headers.vue'
import Welcome from '@/components/Welcome.vue';

export default {
  name: 'HomeView',
  components: {
    Headers,
    Welcome
  },
  data() {
    return {
      times: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Массив с временем с 00:00 до 23:00
      currentDateForWeek: new Date(),
      currentDateForMonth: new Date(),
      showEventsPopup: false,
      currentDayEvents: [],
      page: '',
      currentWeek: [],
      events: [],
      eventsForCalendar: [],
      eventForPopup: {},
      weekdays: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
      timeSlots: [...Array(24).keys()].map(i => `${i.toString().padStart(2, '0')}:00`),
      selectedDateText: 'на сегодня',
      selectedDateTextPlaceHolder: 'на сегодня',
      filteredEvents: [],
      isLinkCopied: false,
      mapEventLink: null,
      selectedEvent: null, // Переменная для хранения выбранного мероприятия
      map: null,
      showEventModal: false,
      isPopupVisible: false, // Флаг видимости попапа
      selectedDay: null,
      imageCache: {},
      processedImages: {}, // Реактивное свойство для обработанных изображений
      ratioMob: 0.914,
      ratioDec: 0.30769230769230769230769230769231,
      eventHeight: '300px',
      loading: true,
      scrollPosition: 0,
      shouldProcessImages: true,  // Флаг для контроля выполнения
      flagNewEvents: false,
      calendarStart: "2024-05-01T00:00:00",
      calendarEnd: "2024-06-01T00:00:00",
      calendarCategories: []
    };
  },
  watch: {
    events: {
      async handler(newEvents) {
      if (this.page === '') {
        this.loading = true;
        this.shouldProcessImages = true;

        for (let event of newEvents) {
          if (!this.shouldProcessImages) {
            break;
          }
          if (event.rect) {
            this.processedImages[event.id] = await this.getImgCrop(event);
          } else {
            this.processedImages[event.id] = this.getImgUrl(event.posterName);
          }
        }

        this.loading = false;
      }
    },
      deep: true
    },
    eventsForCalendar: {
      handler() {
        this.$forceUpdate();        
        this.loading = false;
      },
      deep: true
    }
  },
  created() {
    this.checkAndOpenPopup();
    this.currentWeek = this.getWeekDays();
    this.weekdays = this.getWeekDays();
  },
  updated() {
    // После обновления компонента добавляем обработчики событий
    this.addEventHandlers();
  },
  computed: {
    // FOR WEEK 
    weekDateRange() {
      if (this.weekdays.length === 0) return ''; // Проверка на наличие данных
      const startDate = this.weekdays[0].date;
      const endDate = this.weekdays[this.weekdays.length - 1].date;
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const startMonth = startDate.toLocaleString('default', { month: 'long' });
      const endMonth = endDate.toLocaleString('default', { month: 'long' });
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      // Форматируем строку для отображения диапазона дат и месяца
      const dateRangeString = `${startDay}-${endDay} ${startMonth}`;
      // Добавляем год, если первый и последний день недели в разных годах
      if (startYear !== endYear) {
        return `${dateRangeString} ${startYear}-${endYear}`;
      } else {
        return dateRangeString;
      }
    },
    // FOR WEEK 

    // FOR MONTH 
    currentDateText() {
      if (this.selectedDay !== null) {
        const selectedDate = new Date(this.currentDateForMonth);
        selectedDate.setDate(this.selectedDay.getDate());
        return selectedDate.toLocaleString('default', { day: 'numeric', month: 'long' });
      } else {
        return this.currentDateForMonth.toLocaleString('default', { month: 'long' });
      }
    },
    currentMonth() {
      return this.currentDateForMonth.toLocaleString('default', { month: 'long', year: 'numeric' });
    },
    daysInMonth() {
      const days = [];
      const currentDate = this.currentDateForMonth;
      const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

      let prevMonthDays = firstDay === 0 ? 6 : firstDay - 1;
      let nextMonthDays = 0;

      if (firstDay !== 0) {
        prevMonthDays = firstDay - 1;
      }

      const totalDays = prevMonthDays + lastDayOfMonth;
      const totalWeeks = Math.ceil(totalDays / 7);
      const totalCells = totalWeeks * 7;

      if (totalCells === 35) {
        nextMonthDays = 35 - totalDays;
      } else if (totalCells === 42) {
        nextMonthDays = 42 - totalDays;
      } else {
        nextMonthDays = 7 - ((totalDays % 7) || 7);
      }

      // Добавляем дни предыдущего месяца
      for (let i = prevMonthDays; i > 0; i--) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), -i + 1);
        days.push({ date, events: this.getEventsForDate(date) });
      }

      // Добавляем дни текущего месяца
      for (let i = 1; i <= lastDayOfMonth; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        days.push({ date, events: this.getEventsForDate(date) });
      }

      // Добавляем дни следующего месяца
      for (let i = 1; i <= nextMonthDays; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, i);
        days.push({ date, events: this.getEventsForDate(date) });
      }

      return days;
    },

    isPrevMonthDisabled() {
      return new Date().getFullYear() === this.currentDateForMonth.getFullYear() && new Date().getMonth() === this.currentDateForMonth.getMonth();
    },
    isNextMonthDisabled() {
      return false; // You can define logic to disable next month if needed
    }
    // FOR MONTH 
  },
  methods: {

    getWidth() {
      let event = document.querySelector('.header__inner')
      let result;
      if (window.innerWidth < 1025) {
        result = event.offsetWidth * this.ratioMob + 'px'
      } else {
        result = event.offsetWidth * this.ratioDec + 'px'
      }
      this.eventHeight = result;
    },
    getImgUrl(posterName) {
      return `/api/img/posters/${posterName}`;
    },
    getImgCropCon(event) {
      var imagePath = `/api/img/posters/${event.posterName}`;
      const { x, y, w, h } = event.rect;

      var originalImage = new Image();
      originalImage.crossOrigin = "Anonymous"; // Решает проблемы с CORS, если они возникают
      originalImage.src = imagePath;

      originalImage.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        var ctx = canvas.getContext('2d');

        ctx.drawImage(originalImage, x, y, w, h, 0, 0, w, h);

        var finishUrl = canvas.toDataURL();

        var image = document.getElementById(`imgCor-${event.id}`);
        if (image) {
          image.src = finishUrl;
        }
      };
    },
    getImgCropMap(event) {
      var imagePath = `/api/img/posters/${event.posterName}`;
      const { x, y, w, h } = event.rect;

      var originalImage = new Image();
      originalImage.crossOrigin = "Anonymous"; // Решает проблемы с CORS, если они возникают
      originalImage.src = imagePath;

      originalImage.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        var ctx = canvas.getContext('2d');

        ctx.drawImage(originalImage, x, y, w, h, 0, 0, w, h);

        var finishUrl = canvas.toDataURL();

        var image = document.getElementById(`img-${event.id}`);
        if (image) {
          image.src = finishUrl;
        }
      };
    },
    async cacheImages(events) {
      for (let event of events) {
        if (event.rect && !this.imageCache[event.id]) {
          this.imageCache[event.id] = await this.getImgCrop(event);
        }
      }
    },
    getImgCrop(event) {
      if (this.imageCache[event.id]) {
        return this.imageCache[event.id];
      } else {
        const imagePath = `/api/img/posters/${event.posterName}`;
        const { x, y, w, h } = event.rect;
        return new Promise((resolve) => {
          const originalImage = new Image();
          originalImage.crossOrigin = "Anonymous";
          originalImage.src = imagePath;

          originalImage.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(originalImage, x, y, w, h, 0, 0, w, h);

            const finishUrl = canvas.toDataURL();
            this.imageCache[event.id] = finishUrl;
            resolve(finishUrl);
          };
        });
      }
    },
    getDayClass(daysInMonth) {
      let className = '';
      if (this.daysInMonth.length === 28) {
        className += 'week4 ';
      } else if (this.daysInMonth.length == 35) {
        className += 'week5 ';
      } else if (this.daysInMonth.length == 42) {
        className += 'week6 ';
      }
      return className.trim();
    },
    setFirstEventByTitle() {
      const firstEventTitles = new Set();
      const eventTitleElements = document.getElementsByClassName('week__event-title');
      for (const titleElement of eventTitleElements) {
        const eventTitle = titleElement.textContent.trim();
        if (!firstEventTitles.has(eventTitle)) {
          firstEventTitles.add(eventTitle);
          let currentElement = titleElement;
          while (currentElement && !currentElement.classList.contains('week__event')) {
            currentElement = currentElement.parentElement;
          }
          if (currentElement) {
            currentElement.classList.add('first');
          }
        }
      }
    },
    getEventTimeSlots() {
      return this.timeSlots;
    },
    isEventInDayAndTime(event, date, time) {

      const eventStartTime = new Date(event.startTime);
      const eventEndTime = new Date(event.finishTime);
      const targetDay = new Date(date);
      const [hours, minutes] = time.split(':');

      targetDay.setHours(parseInt(hours), parseInt(minutes), 0, 0);

      return eventStartTime <= targetDay && eventEndTime > targetDay;

    },
    // FOR WEEK 
    isTodayWeek(date) {
      const today = new Date(); // Получаем текущую дату
      // Проверяем, является ли дата сегодняшней
      const isToday = date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
      return isToday;
    },
    getWeekDays() {
      setTimeout(() => {
        this.setFirstEventByTitle();
      }, 100);
      const daysOfWeek = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
      const startOfWeek = this.getWeekStartDate(this.currentDateForWeek);
      const weekDays = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(date.getDate() + i);
        weekDays.push({ dayOfWeek: daysOfWeek[i], date: date });
      }
      return weekDays;
    },
    getDateInCurrentWeek(dayOfWeek) {
      return dayOfWeek.getDate();
    },
    getDateInCurrentWeekForToday(dayOfWeek) {
      const currentWeekDays = this.getWeekDays(); // Получаем даты текущей недели
      const currentDate = currentWeekDays[dayOfWeek]; // Получаем дату для указанного дня недели
      return currentDate.date; // Возвращаем объект даты
    },

    getWeekStartDate(date) {
      const dayOfWeek = date.getDay(); // Получаем день недели (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
      const diff = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Вычисляем разницу между днем недели и понедельником (если воскресенье, то 6 дней назад)
      const startOfWeek = new Date(date); // Создаем новый объект Date на основе переданной даты
      startOfWeek.setDate(date.getDate() - diff); // Устанавливаем дату на начало текущей недели
      return startOfWeek; // Возвращаем начальную дату недели
    },

    nextWeek() {
      setTimeout(() => {
        this.setFirstEventByTitle();
      }, 100);
      this.currentDateForWeek.setDate(this.currentDateForWeek.getDate() + 7);
      this.weekdays = this.getWeekDays(); // Обновляем массив дней недели
    },

    prevWeek() {
      setTimeout(() => {
        this.setFirstEventByTitle();
      }, 100);
      this.currentDateForWeek.setDate(this.currentDateForWeek.getDate() - 7);
      this.weekdays = this.getWeekDays(); // Обновляем массив дней недели
    },
    isPrevWeekDisabled() {
      const today = new Date();
      const startOfCurrentWeek = this.getWeekStartDate(today);
      const startOfSelectedWeek = this.getWeekStartDate(this.currentDateForWeek);
      const isToday = startOfCurrentWeek.getFullYear() === startOfSelectedWeek.getFullYear() &&
        startOfCurrentWeek.getMonth() === startOfSelectedWeek.getMonth() &&
        startOfCurrentWeek.getDate() === startOfSelectedWeek.getDate();
      return isToday;
    },
    // FOR WEEK 

    // FOR MONTH 
    showPopupEvents(events) {
      document.querySelector('html').classList.add('showPopup');
      setTimeout(() => {
        document.querySelector('html').classList.add('animShowPopup');
        this.initMap();
      }, 10);
      this.currentDayEvents = events; // Сохраняем события дня в переменную
      this.showEventsPopup = true; // Отображаем попап
    },
    hidePopupEvents() {
      document.querySelector('html').classList.remove('animShowPopup');
      setTimeout(() => {
        document.querySelector('html').classList.remove('showPopup');
      }, 500);
      this.showEventsPopup = false; // Скрываем попап
    },
    getEventLink(event) {
      const mapBaseLink = `${window.location.origin}`;
      return `${mapBaseLink}?eventId=${event.id}`;
    },
    isToday(day) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (day.getTime() === today.getTime());
    },

    async prevMonth() {
      this.loading = true;
      this.currentDateForMonth = new Date(this.currentDateForMonth.getFullYear(), this.currentDateForMonth.getMonth() - 1, 1);
      this.handleCategoriesFilter(0)
    },
    async nextMonth() {
      this.loading = true;
      this.currentDateForMonth = new Date(this.currentDateForMonth.getFullYear(), this.currentDateForMonth.getMonth() + 1, 1);
      this.handleCategoriesFilter(0)
    },
    getEventsForDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const dateString = `${year}-${month}-${day}`; // Форматируем дату как YYYY-MM-DD

      const dayData = this.eventsForCalendar.find(day => day.date === dateString);

      return dayData ? dayData.events : [];
    },
    // FOR MONTH 
    shareEvent() {
      // Создайте ссылку для текущего мероприятия
      const baseLink = `${window.location.origin}${window.location.pathname}`;
      const eventLink = `${baseLink}?eventId=${this.selectedEvent.id}`;

      // Сохраните ссылку в буфер обмена
      navigator.clipboard.writeText(eventLink);

      this.isLinkCopied = true;

      // Спустя 3 секунды сбросьте состояние isLinkCopied
      setTimeout(() => {
        this.isLinkCopied = false;
      }, 1500);
    },
    initMap() {
      if (this.selectedEvent && this.selectedEvent.address &&
        this.selectedEvent.address.latitude && this.selectedEvent.address.longitude) {
        const latitude = this.selectedEvent.address.latitude;
        const longitude = this.selectedEvent.address.longitude;
        var screenWidth = window.innerWidth;
        var iconImageSize;
        if (screenWidth < 640) {
          iconImageSize = [30, 42];
        } else if (screenWidth < 1600) {
          iconImageSize = [35, 50];
        } else {
          iconImageSize = [61, 88];
        }
        try {
          setTimeout(() => {
            const myMap = new ymaps.Map(this.$refs.mapContainer, {
              center: [latitude, longitude],
              zoom: 15
            });

            const placemark = new ymaps.Placemark([latitude, longitude], {
              hintContent: 'Место проведения мероприятия'
            }, {
              iconLayout: 'default#image',
              iconImageHref: 'https://dvig.r7energy.ru/metka3.svg',
              iconImageSize: iconImageSize,
            });

            myMap.geoObjects.add(placemark);
          }, 100);
        } catch (error) {
          console.error("Error initializing Yandex Map:", error);
        }
      }
    },
    initMapList() {
      try {
        this.$refs.mapContainerList.innerHTML = '';

        // Создаем новую карту
        const myMap = new ymaps.Map(this.$refs.mapContainerList, {
          center: [53.195873, 50.100193], // Координаты центра карты (Самара)
          zoom: 10 // Уровень масштабирования карты
        });
        const now = new Date();
        // Добавляем метки на карту
        this.events.forEach(event => {
          if (event.address.latitude) {
            const latitude = event.address.latitude;
            const longitude = event.address.longitude;
            const startTime = new Date(event.startTime);
            const finishTime = new Date(event.finishTime);
            // Проверяем, проходит ли событие прямо сейчас
            if (now >= startTime && now <= finishTime) {
              // Событие проходит прямо сейчас, меняем цвет метки
              var iconImageHref = 'https://dvig.r7energy.ru/metka2.svg';
            } else {
              // Событие не проходит прямо сейчас, используем стандартный цвет метки
              var iconImageHref = 'https://dvig.r7energy.ru/metka1.svg';
            }
            var screenWidth = window.innerWidth;
            var iconImageSize;
            if (screenWidth < 640) {
              iconImageSize = [30, 42];
            } else if (screenWidth < 1600) {
              iconImageSize = [35, 50];
            } else {
              iconImageSize = [61, 88];
            }

            const placemark = new ymaps.Placemark([latitude, longitude], {
              hintContent: event.shortTitle // В качестве подсказки используем название мероприятия
            }, {
              iconLayout: 'default#image',
              iconImageHref: iconImageHref, // Путь к SVG значку
              iconImageSize: iconImageSize, // Размеры значка
            });
            // Добавляем метку на карту
            myMap.geoObjects.add(placemark);

            placemark.events.add('click', (e) => {
              const id = event.id;
              const selectedEvent = this.events.find(event => event.id === id);
              this.selectedEvent = selectedEvent;
              const mapBaseLink = `${window.location.origin}`;
              this.mapEventLink = `${mapBaseLink}?eventId=${this.selectedEvent.id}`;
              this.showEventModal = true;
              document.querySelectorAll('ymaps').forEach(item => {
                item.classList.add('modal-open');
              });
            });
          }

        });
        myMap.events.add('click', () => {
          document.querySelectorAll('ymaps').forEach(item => {
            item.classList.remove('modal-open');
          });
          this.showEventModal = false;
          this.selectedEvent = null;
        });
      } catch (error) {
        console.error("Error initializing Yandex Map:", error);
      }
    },
    handleLoadingState(isLoading, page){
      if (this.page !== ''){
        this.flagNewEvents = false;
      }
      this.page = page;
      this.loading = isLoading;
      if (page !== '') {
        this.shouldProcessImages = false; // Устанавливаем флаг в false, чтобы прервать обработку
      }
    },
    handleFilteredEvents(filteredEvents, selectedDateText, page) {
      this.selectedDateText = selectedDateText;
      this.events = filteredEvents;
      this.page = page;
      switch (selectedDateText) {
        case 'Сегодня':
          this.selectedDateTextPlaceHolder = 'на ' + selectedDateText;
          break;
        case 'Завтра':
          this.selectedDateTextPlaceHolder = 'на ' + selectedDateText;
          break;
        case 'На неделю':
          this.selectedDateTextPlaceHolder = selectedDateText;
          break;
        default:
          this.selectedDateTextPlaceHolder = "в период " + selectedDateText;
          break;
      }
      setTimeout(() => {
        if (this.page === 'map') {
          if (!this.map) {
            this.initMapList();
          }
        }
      }, 1);
      if (page === ''){
        this.getWidth();
        this.flagNewEvents = true;
      } else {
        this.flagNewEvents = false;
      }
      this.showEventModal = false;
      if (page !== '') {
        this.shouldProcessImages = false; // Устанавливаем флаг в false, чтобы прервать обработку
      }
    },
    async handleCategoriesFilter(categories){
      this.loading = true;
      if (categories != 0){
        this.calendarCategories = categories
      }

      await this.$nextTick();

      // Преобразуем даты из daysInMonth в нужный формат
      let startDate = this.daysInMonth[0].date;
      let endDate = this.daysInMonth[this.daysInMonth.length - 1].date;

      // Добавляем один день к endDate
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);

      // Добавляем один день к endDate
      startDate = new Date(startDate);
      startDate.setDate(startDate.getDate() - 1);
      
      
      const formatDate = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00`;
      };
      
      this.calendarStart = formatDate(startDate);
      this.calendarEnd = formatDate(endDate);
      const apiUrl = '/api/pis/events_by_days';
      
      const queryParams = new URLSearchParams({
        start: this.calendarStart,
        end: this.calendarEnd,
        struct: 'false',
        categories: this.calendarCategories,
      });

      // Отменяем предыдущий запрос, если он существует
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Новый запрос отменяет предыдущий.');
      }

      // Создаем новый токен отмены
      this.cancelTokenSource = axios.CancelToken.source();

      try {
        const response = await axios.get(`${apiUrl}?${queryParams}`, {
          cancelToken: this.cancelTokenSource.token
        });
        this.eventsForCalendar = response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          console.error('Ошибка при получении мероприятий:', error);
        }
      }
    },
    async fetchEvents(id) {
      const devParam = window.innerWidth > 1023 ? 'd' : 'm';
      const viewParam = 'c';
      
      const queryParams = new URLSearchParams({
        dev: devParam,
        view: viewParam,
      });
      
      const apiUrl = `/api/pis/event/${id}?${queryParams}`;
      axios.get(apiUrl)
        .then(response => {
          this.openPopup(response.data);
        })
        .catch(error => {
          document.querySelector('html').classList.remove('animShowPopup');
          setTimeout(() => {
            document.querySelector('html').classList.remove('showPopup');
          }, 500);
        });
    },
    checkAndOpenPopup() {
      const eventId = this.$route.query.eventId;
      if (eventId) {
        const eventIdNumber = parseInt(eventId, 10);
        this.fetchEvents(eventIdNumber);
      }
    },
    getDayDifference(startTime, finishTime) {
      // Преобразование в объекты дат
      const startDate = new Date(startTime);
      const finishDate = new Date(finishTime);

      const userTimezoneOffset = startDate.getTimezoneOffset() / 60 * -1;

      startDate.setHours(startDate.getHours() + userTimezoneOffset);
      finishDate.setHours(finishDate.getHours() + userTimezoneOffset);

      // Вычисление разницы в миллисекундах
      const timeDiff = finishDate - startDate;
      const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return dayDiff > 0 ? '+' + dayDiff : '';
    },
    formatDate(start, end) {
      const months = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
      ];

      const startDate = new Date(start);
      const endDate = new Date(end);

      const userTimezoneOffset = startDate.getTimezoneOffset() / 60 * -1

      startDate.setHours(startDate.getHours() + userTimezoneOffset)
      endDate.setHours(endDate.getHours() + userTimezoneOffset)


      const day = startDate.getDate();
      const month = months[startDate.getMonth()];
      const hours = startDate.getHours().toString().padStart(2, '0');
      const minutes = startDate.getMinutes().toString().padStart(2, '0');

      const endHours = endDate.getHours().toString().padStart(2, '0');
      const endMinutes = endDate.getMinutes().toString().padStart(2, '0');

      return `${day} ${month}, ${hours}:${minutes} - ${endHours}:${endMinutes}`;
    },

    formatDateTime(start, end) {
      const months = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
      ];

      const date = new Date(start);
      const endDate = new Date(end);

      const userTimezoneOffset = date.getTimezoneOffset() / 60 * -1

      date.setHours(date.getHours() + userTimezoneOffset)
      endDate.setHours(endDate.getHours() + userTimezoneOffset)

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      const endHours = endDate.getHours().toString().padStart(2, '0');
      const endMinutes = endDate.getMinutes().toString().padStart(2, '0');

      return `${hours}:${minutes}-${endHours}:${endMinutes}`;
    },
    formattedDescr(description) {
      let formattedDescription = description.replace(/\n/g, '<br>');
      return formattedDescription;
    },
    formatDatePopup(timestamp) {
      const months = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
      ];

      const date = new Date(timestamp);
      const day = date.getDate();
      const month = months[date.getMonth()];

      return `${day} ${month}`;
    },
    formatTimePopup(startTime, endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);

      const userTimezoneOffset = start.getTimezoneOffset() / 60 * -1

      start.setHours(start.getHours() + userTimezoneOffset)
      end.setHours(end.getHours() + userTimezoneOffset)

      const startHours = start.getHours().toString().padStart(2, '0');
      const startMinutes = start.getMinutes().toString().padStart(2, '0');

      const endHours = end.getHours().toString().padStart(2, '0');
      const endMinutes = end.getMinutes().toString().padStart(2, '0');

      return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
    },

    openPopup(event) {
      if (event) {
        this.selectedEvent = event;
        this.isPopupVisible = true;
        this.scrollPosition = window.scrollY;
        document.querySelector('html').classList.add('showPopup');
        setTimeout(() => {
          document.querySelector('html').classList.add('animShowPopup');
          this.initMap();
        }, 10);
      }
    },
    hideEventsPopupAndOpenPopup(event) {
      this.showEventsPopup = false; // Скрываем попап
      this.fetchEvents(event.id)
    },
    closePopup() {
      this.isPopupVisible = false;
      document.querySelector('html').classList.remove('animShowPopup');
      window.scrollTo(0, this.scrollPosition);
      setTimeout(() => {
        document.querySelector('html').classList.remove('showPopup');
      }, 500);


    },
    addEventHandlers() {
      this.$nextTick(() => {
        // Используем this.$refs.eventsInner для получения ссылки на .events__inner
        if (this.$refs.eventsInner) {
          let events = this.$refs.eventsInner.querySelectorAll('.events__item');
          events.forEach(item => {
            item.addEventListener('click', () => {
              this.openPopup(item.dataset.eventId); // Передаем идентификатор мероприятия
            });
          });
        }

      });

      let eventFav = document.querySelectorAll('.events__item-fav');
      eventFav.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.stopPropagation();
          item.classList.toggle('active');
        });
      });
    }

  },
  mounted() {
    const eventId = this.$route.query.eventId;
    if (eventId) {
      document.querySelector('html').classList.add('showPopup');
      setTimeout(() => {
        document.querySelector('html').classList.add('animShowPopup');
        this.initMap();
      }, 10);
    }
    this.addEventHandlers();
    const currentPath = this.$route.path;
    if (currentPath === '/') {
      this.page = '';
    } else if (currentPath === '/map') {
      this.page = 'map';
    } else if (currentPath === '/calendar') {
      this.page = 'calendar';
    }
    if (this.page == 'calendar') {
      this.$nextTick(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        document.body.appendChild(script);
        setTimeout(() => {
          let btns12 = document.querySelectorAll('.fc-button');
          btns12.forEach(element => {
            element.addEventListener('click', () => {
              setTimeout(() => {
                addSpan();
                addTodayClass();
              }, 1);
            });
          });
          addSpan();
          addTodayClass();
        }, 1000);
        function addSpan() {
          // Получаем все элементы с классом fc-col-header-cell-cushion
          const elements = document.querySelectorAll('.fc-col-header-cell-cushion');

          // Проходимся по каждому элементу
          elements.forEach(element => {
            // Получаем текст из элемента
            const text = element.innerHTML; // Используем innerHTML вместо innerText

            // Разбиваем текст по запятой
            const parts = text.split(',');

            // Если есть текст после запятой
            if (parts.length > 1) {
              // Создаем элемент <span> для текста после запятой
              const span = document.createElement('span');
              span.textContent = parts[1].trim(); // Удаляем лишние пробелы

              // Добавляем <span> в элемент
              element.innerHTML = parts[0].trim() + span.outerHTML; // Обновляем innerHTML с учетом span
            }
          });
        }
        function addTodayClass() {
          // Находим элемент с классом fc-day-today
          const todayElement = document.querySelector('.fc-day-today');

          // Проверяем, что такой элемент существует
          if (todayElement) {
            // Находим дочерний элемент с классом fc-col-header-cell-cushion
            const cushionElement = todayElement.querySelector('.fc-col-header-cell-cushion');

            // Проверяем, что такой дочерний элемент существует
            if (cushionElement) {
              // Добавляем класс today к дочернему элементу
              cushionElement.classList.add('today');
            }
          }
        }

      });
    }
  }
};
</script>
